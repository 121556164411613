import React from 'react';
import { Button, Row, Col } from 'antd';
import { AndroidOutlined, AppleOutlined ,ChromeOutlined} from '@ant-design/icons';
import './css/download.css';

const DownloadButtons = () => {
  return (
    <div>
      <div className="download-buttons">
        <Row gutter={[16, 16]} justify="center">
          <Col>
            <Button className="download-button google-play" href="https://play.google.com" target="_blank">
              <AndroidOutlined style={{ fontSize: '30px' }} />
              <div className="button-text">
                <span className="small-text">GET IT ON</span>
                <span className="large-text">Google Play</span>
              </div>
            </Button>
          </Col>
          <Col>
            <Button className="download-button app-store" href="https://apps.apple.com/us/app/pathplan-ai-trip-planner/id6711356228" target="_blank">
              <AppleOutlined style={{ fontSize: '30px' }} />
              <div className="button-text">
                <span className="small-text">Download on the</span>
                <span className="large-text">App Store</span>
              </div>
            </Button>
          </Col>
        </Row>
      </div>
      <div className="download-buttons">
        <Row gutter={[16, 16]} justify="center">
          <Col>
            <Button className="download-button google-play" href="https://webapp-pathplan.web.app/" target="_blank" style={{background:"#09F"}}>
              <ChromeOutlined style={{ fontSize: '30px' }} />
              <div className="button-text">
                <span className="small-text">GET IT WEB</span>
                <span className="large-text">Browse Now</span>
              </div>
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DownloadButtons;
