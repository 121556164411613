import React from 'react';
import { Input, DatePicker, Select, Button, Typography, Row, Col, Card } from 'antd';
import './css/first_section.css';
import DownloadButtons from './download';
import firstImage from '../assets/image/first_image.png';
const { Title, Text } = Typography;
const { Option } = Select;

const FirstSection = () => {
  return (
    <section className="hero-section">
      <Row gutter={[24, 24]} align="middle" className="hero-content">
        <Col xs={24} md={10}>
          <Title  className="hero-title">
          Effortless Adventure <span className="highlight">Planning</span> with <span className="highlight">PathPlan</span>
          </Title>
          <Text className="hero-subtitle">
            Plan your adventure effortlessly with PathPlan! Using AI, PathPlan crafts personalized itineraries, schedules, and recommendations for every stop, making travel easy.
          </Text>
          <div className="search-form">
            <DownloadButtons/>
          </div>
        </Col>
        <Col md={4}>
        
        </Col>
        <Col xs={24} md={10} className="image-gallery">
          <Row gutter={[16, 16]}>
            <img src={firstImage} height={300}/>
          </Row>
        </Col>
      </Row>
    </section>
  );
};

export default FirstSection;
