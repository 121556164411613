import React, { useEffect, useState } from 'react';
import { Typography, Row, Col, Card } from 'antd';
import './css/second_section.css';

const { Title, Text } = Typography;

const categories = [
  { name: 'Indianapolis', image_url: 'https://firebasestorage.googleapis.com/v0/b/flutter-ai-tripmaker.appspot.com/o/cities%2Findianapolis_IN.jpg?alt=media&token=8b4e0256-915a-40d5-b3fa-77f3a4ac5d39' },
  { name: 'Kansas City', image_url: 'https://firebasestorage.googleapis.com/v0/b/flutter-ai-tripmaker.appspot.com/o/cities%2Fkansas_city_MO.jpg?alt=media&token=fb97c815-695c-4f02-876f-870cd47fc667' },
  { name: 'San Francisco', image_url: 'https://firebasestorage.googleapis.com/v0/b/flutter-ai-tripmaker.appspot.com/o/cities%2Fsan_francisco_CA.jpg?alt=media&token=082a37c8-adc0-453c-8ff6-429f30ad5b63' },
  { name: 'Boston', image_url: 'https://firebasestorage.googleapis.com/v0/b/flutter-ai-tripmaker.appspot.com/o/cities%2Fboston_MA.jpg?alt=media&token=16b56fa7-9e81-4587-a576-7362ef7a40a5' },
  { name: 'Miami', image_url: 'https://firebasestorage.googleapis.com/v0/b/flutter-ai-tripmaker.appspot.com/o/cities%2Fmiami_FL.jpg?alt=media&token=f08fb3be-609d-4374-bd0b-ec6d87253e1f' },
  { name: 'New Orleans', image_url: 'https://firebasestorage.googleapis.com/v0/b/flutter-ai-tripmaker.appspot.com/o/cities%2Fnew_orleans_LA.jpg?alt=media&token=3f814b41-6a14-4a8d-b108-712ed3b11ece' },
];

const SecondSection = () => {
  // const [categories, setCategories] = useState(categories);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(async ()=>{
  //   const cities = await getCities();
  //   const newCategories = [];
  //   for(var i=0;i<6;i++){
  //     newCategories.push({
  //       name: cities[i].name,
  //       image_url: cities[i].image_url,
  //     });
  //   }
  //   setCategories(newCategories);
  // });

  return (
    <section className="categories-section">
      {/* <Title level={2}>Categories</Title>
      <Text className="description">
        Here are lots of interesting destinations to visit, but don’t be confused—they’re already grouped by category.
      </Text> */}
      <Row gutter={[24, 24]} justify="center">
        {categories.map((category, index) => (
          <Col xs={24} sm={12} md={8} lg={4} key={index}>    
                <div className="category-content">
                    <img alt={category.name} src={category.image_url} className="category-image" />
                </div><br/>
                <div className="category-content">
                    <Text className="category-title">{category.name}</Text>
                </div>
          </Col>
        ))}
      </Row>
    </section>
  );
};

export default SecondSection;
