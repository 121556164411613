import React from 'react';
import { Layout, Typography, Row, Col, Space } from 'antd';
import { FacebookOutlined, TwitterOutlined, InstagramOutlined } from '@ant-design/icons';
import './css/footer.css';

const { Footer } = Layout;
const { Text, Link } = Typography;

const AppFooter = () => {
  return (
    <Footer className="footer">
      <Row justify="space-between" align="middle" className="footer-top">
        <Col>
          <Typography.Title level={4} className="footer-logo">
            <p><span className="logo-text">P</span>ath <span className="logo-text">P</span>lan</p>
          </Typography.Title>
        </Col>
        <Col>
          <Space size="large" className="footer-links">
            <Link href="#home">Home</Link>
            <Link href="#about">About Us</Link>
            <Link href="#products">Products</Link>
            <Link href="#faq">FAQ</Link>
            <Link href="#contact">Contact</Link>
          </Space>
        </Col>
      </Row>
      <Row justify="space-between" align="middle" className="footer-bottom">
        <Col>
          <Text style={{color: "#fff",fontFamily: "app_font_medium"}}>© 2024 PathPlan Inc.</Text>
        </Col>
        <Col>
          <Space size="large">
            <Link href="#imprint" style={{fontFamily: "app_font_medium"}}>Imprint</Link>
            <Link href="#privacy" style={{fontFamily: "app_font_medium"}}>Privacy</Link>
            <Space size="small" className="social-icons">
              <Link href="https://facebook.com" target="_blank"><FacebookOutlined /></Link>
              <Link href="https://twitter.com" target="_blank"><TwitterOutlined /></Link>
              <Link href="https://instagram.com" target="_blank"><InstagramOutlined /></Link>
            </Space>
          </Space>
        </Col>
      </Row>
    </Footer>
  );
};

export default AppFooter;
