import React from 'react';
import { Menu, Button, Typography } from 'antd';
import { useMediaQuery } from 'react-responsive';
import './css/header.css';

const Header = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <header className="header">
      <div className="logo">
        <Typography.Title level={4} style={{ margin: 0 }}>
          <span className="logo-text">P</span>ath <span className="logo-text">P</span>lan
        </Typography.Title>
      </div>
      <Menu mode={isMobile ? 'vertical' : 'horizontal'} className="menu" selectable={false}>
        <Menu.Item key="home">Home</Menu.Item>
        <Menu.Item key="about">About</Menu.Item>
        <Menu.Item key="packages">Packages</Menu.Item>
        <Menu.Item key="community">Community</Menu.Item>
      </Menu>
      {/* <Button type="primary">
        Sign up
      </Button> */}
    </header>
  );
};

export default Header;
