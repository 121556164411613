import logo from './logo.svg';
import './App.css';
import { Button } from 'antd';
import Header from './components/header';
import FirstSection from './components/first_section';
import SecondSection from './components/second_section';
import ThirdSection from './components/third_section';
import AppFooter from './components/footer';
import "./components/css/font.css";
function App() {
  return (
    <div className="App">
      <Header />
      <FirstSection/>
      <SecondSection/>
      <ThirdSection />
      <AppFooter />
    </div>
  );
}

export default App;
