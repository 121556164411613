import React from 'react';
import { Card, Typography, Button, Row, Col } from 'antd';
import { CarOutlined, HomeOutlined, RightCircleOutlined, RocketOutlined } from '@ant-design/icons';
import './css/third_section.css';
import itineraryScreen from "./../assets/image/third_image.png";

const { Title, Text } = Typography;

const ThirdSection = () => {
  return (
    <section className="booking-options-section">
      <Row gutter={[24, 24]} align="middle">
        <Col xs={24} md={12}>
          <Card className="options-card" bordered={false}>
          <div className="option-item">
              <div>
                <Row>
                  <Col span={4} style={{ display:'flex',justifyContent:"left"}}><RocketOutlined className="option-icon" /></Col>
                  <Col span={12} style={{ display:'flex',justifyContent:"left"}}><p level={4} className="option-title">Create Trip Plan</p></Col>
                </Row>
                <Text className="option-description">
                Craft a personalized itinerary effortlessly with PathPlan's AI-driven planning.
                </Text>
                <Button type="link" className="explore-button" icon={<RightCircleOutlined />}>
                  Explore more
                </Button>
              </div>
            </div>
            <div className="option-item">
             
              <div>
                <Row>
                  <Col span={4} style={{ display:'flex',justifyContent:"left"}}> <CarOutlined className="option-icon" /></Col>
                  <Col span={12} style={{ display:'flex',justifyContent:"left"}}><p level={4} className="option-title">Book Flights</p></Col>
                </Row>
                <Text className="option-description">Easily find and book flights tailored to your travel needs and schedule.</Text>
                <Button type="link" className="explore-button" icon={<RightCircleOutlined />}>
                  Explore more
                </Button>
              </div>
            </div>
            <div className="option-item">
              
              <div>
                <Row>
                  <Col span={4} style={{ display:'flex',justifyContent:"left"}}><HomeOutlined className="option-icon" /></Col>
                  <Col span={12} style={{ display:'flex',justifyContent:"left"}}><p level={4} className="option-title">Book Stay</p></Col>
                </Row>
                <Text className="option-description">
                Secure the perfect accommodations to match your destination and budget.
                </Text>
                <Button type="link" className="explore-button" icon={<RightCircleOutlined />}>
                  Explore more
                </Button>
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} md={2} className="arrow-container">
          <span className="arrow-icon">→</span>
        </Col>
        <Col xs={24} md={10}>
          <img alt="scenic" src={itineraryScreen} height={500}/>
        </Col>
      </Row>
    </section>
  );
};

export default ThirdSection;
